import React from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router-dom';

import { User } from './models/user';
import { getCurrentUser } from './api/admin/session';
import { AppLoader } from './components/loader/app-loader';
import { PATH, SLUG } from './constants/path';
import { AppContext, AppContextValue } from './app-context';

const PublicPage = React.lazy(() => import('./pages/public-page/public-page'));
const LoginPage = React.lazy(() => import('./pages/login-page/login-page'));
const PrivatePage = React.lazy(
  () => import('./pages/private-page/private-page')
);
const CustomerPage = React.lazy(
  () => import('./pages/customer-page/customer-page')
);
const DocumentPage = React.lazy(
  () => import('./pages/document-page/document-page')
);
const BalancePage = React.lazy(
  () => import('./pages/balance-page/balance-page')
);
const TemplatePage = React.lazy(
  () => import('./pages/template-page/template-page')
);
const RulePage = React.lazy(() => import('./pages/rule-page/rule-page'));
const NotificationTypePage = React.lazy(
  () => import('./pages/notification-type-page/notification-type-page')
);
const NotificationPage = React.lazy(
  () => import('./pages/notification-page/notification-page')
);
const SettingsPage = React.lazy(
  () => import('./pages/settings-page/settings-page')
);

export const App: React.FC = () => {
  const [user, setUser] = React.useState<User | null>(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const appContext = React.useMemo<AppContextValue>(
    () => ({
      user,
    }),
    [user]
  );

  React.useEffect(() => {
    getCurrentUser()
      .then(setUser)
      .catch(() => undefined)
      .finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return <AppLoader />;
  }

  return (
    <AppContext.Provider value={appContext}>
      <Routes>
        <Route
          index={true}
          element={
            <Navigate
              to={
                user
                  ? generatePath(PATH.CUSTOMER_PAGE)
                  : generatePath(PATH.LOGIN_PAGE)
              }
            />
          }
        />
        <Route element={<PublicPage />}>
          <Route path={SLUG.LOGIN} element={<LoginPage />} />
        </Route>
        <Route
          element={
            user ? (
              <PrivatePage user={user} />
            ) : (
              <Navigate to={generatePath(PATH.LOGIN_PAGE)} replace={true} />
            )
          }
        >
          <Route
            index={true}
            element={
              <Navigate to={generatePath(PATH.CUSTOMER_PAGE)} replace={true} />
            }
          />
          <Route path={SLUG.CUSTOMER} element={<CustomerPage />} />
          <Route path={SLUG.DOCUMENT} element={<DocumentPage />} />
          <Route path={SLUG.BALANCE} element={<BalancePage />} />
          <Route path={SLUG.TEMPLATE} element={<TemplatePage />} />
          <Route path={SLUG.RULE} element={<RulePage />} />
          <Route
            path={SLUG.NOTIFICATION_TYPE}
            element={<NotificationTypePage />}
          />
          <Route path={SLUG.NOTIFICATION} element={<NotificationPage />} />
          <Route path={SLUG.SETTINGS} element={<SettingsPage />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
};
