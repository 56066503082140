import axios, { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

import { generatePublicPathWithQuery } from 'app/utils/generate-public-path-with-query';

import { AppConfig } from './app-config';
import { PATH } from './path';

export const axiosInstance = axios.create({
  baseURL: AppConfig.publicUrl ? `${AppConfig.publicUrl}/api` : '/api',
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = generatePublicPathWithQuery(PATH.ROOT_PAGE);

      return;
    }

    throw error;
  }
);
