import { generatePath, Params } from 'react-router-dom';

export const generatePathWithQuery = (
  path: string,
  params?: Params,
  query?: Record<string, string | string[] | null>
) => {
  const url = generatePath(path, params);
  const queryString = query
    ? Object.entries(query)
        .flatMap<[string, string]>(([queryParamName, queryParamValue]) => {
          if (!queryParamValue) {
            return [];
          }

          const normalizedQueryParamValues =
            typeof queryParamValue === 'string'
              ? [queryParamValue]
              : queryParamValue;

          console.log('qq', queryParamValue, normalizedQueryParamValues);

          return normalizedQueryParamValues.map<[string, string]>((value) => [
            queryParamName,
            value,
          ]);
        })
        .reduce((urlSearchParams, queryParam) => {
          urlSearchParams.append(queryParam[0], queryParam[1]);

          return urlSearchParams;
        }, new URLSearchParams())
        .toString()
    : null;

  return queryString ? `${url}?${queryString}` : url;
};
