export const SLUG = {
  LOGIN: 'login',
  CUSTOMER: 'customer',
  DOCUMENT: 'document',
  BALANCE: 'balance',
  TEMPLATE: 'template',
  RULE: 'rule',
  NOTIFICATION_TYPE: 'notification-type',
  NOTIFICATION: 'notification',
  SETTINGS: 'settings',
};

export const PATH = {
  ROOT_PAGE: '/',
  LOGIN_PAGE: `/${SLUG.LOGIN}`,
  CUSTOMER_PAGE: `/${SLUG.CUSTOMER}`,
  DOCUMENT_PAGE: `/${SLUG.DOCUMENT}`,
  BALANCE_PAGE: `/${SLUG.BALANCE}`,
  TEMPLATE_PAGE: `/${SLUG.TEMPLATE}`,
  RULE_PAGE: `/${SLUG.RULE}`,
  NOTIFICATION_TYPE_PAGE: `/${SLUG.NOTIFICATION_TYPE}`,
  NOTIFICATION_PAGE: `/${SLUG.NOTIFICATION}`,
  SETTINGS_PAGE: `/${SLUG.SETTINGS}`,
};
